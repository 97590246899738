import React, { useState } from "react";
import cn from "classnames";
import { Footer, Header, NoJs, Theme, Modal, Button } from "~components";
import * as styles from "./styles.module.scss";
import "~node_modules/modern-normalize/modern-normalize.css";
import "~styles/global.css";

interface IProps {
  children: React.ReactNode;
  className?: string;
  location: {
    pathname: string;
  };
  pageContext: {
    id?: string;
  };
}

const Layout = ({ children, className, location, pageContext }: IProps) => {
  const { id } = pageContext;
  const [isModalVisible, setModalVisible] = useState(true);

  const closeModal = () => setModalVisible(false);

  return (
    <>
      {id && <Header location={location} />}
      <NoJs />
      <Theme />

      <Modal isVisible={isModalVisible} onClose={closeModal} title="Token Buyout Program">
        <div style={{ textAlign: 'center' }}>
          <p className="b2" style={{ marginBottom: '2rem' }}>
          Following the passage of the Token Buyout Program <a href="https://governance.lucidao.com/#/proposal/225-proposal_by_a_consortium_of_third_party_investors_led_by_ai_capital_cyprus_ltd_to_purchase_and_acquire_all_lucidao_tokens_for_real_world_asset_expansion" target="blank" style={{ textDecorationLine: "underline" }}>proposal</a> by AI Capital Cyprus LTD, LCD holders can now exchange their tokens for USDt at a fixed rate until November 20, 2024. After this period, any remaining LCD tokens will be invalid and carry no claim.
          </p>
          <Button variant="secondary" href="https://buyout.lucidao.com">
            Access the buyout
          </Button>
        </div>
      </Modal>

      <div className={cn(styles.container, className)}>{children}</div>
      {id && <Footer />}
    </>
  );
};

export default Layout;